<template>
  <div style="display: inline-flex; width: 80%">
    <div id="form_newComment"  >
      <h1>Ajouter un commentaire</h1>
      <label for="">Auteur/Entreprise</label>
      <input type="text" v-model="new_comment.comment_author" />
      <label for="">Commentaire</label>
      <textarea
        cols="30"
        rows="10"
        v-model="new_comment.comment_text"
      ></textarea>
      <button @click="add">Ajouter</button>
    </div>
    <div id="comment_container">
      <div
        v-for="comment in commentaries"
        :key="comment._id"
        class="card_comment"
      >
      <img class="btn_delete"
          src="../../../assets/poubelle.png"
          @click="remove(comment._id)"
          alt=""
        />
        <h2>{{ comment.comment_author }}</h2>

        <p>{{ comment.comment_text }}</p>
        
      </div>
    </div>
  </div>
</template>
<script>
//NPM
import axios from "axios";

//API
import collect_api from "../../../api/collect";

//Components
import snackbar from "../../../components/Object/snackbar";


export default {
  name: "Admin.commentary",
  data() {
    return {
      commentaries: [],
      new_comment: {
        comment_author: "",
        comment_text: "",
      },
    };
  },
  mounted() {
    collect_api.get("/comments").then((comments) => {
      this.commentaries = comments;
    });
  },

  methods: {
    add() {
      axios
        .post("/comments", {
          comment_author: this.new_comment.comment_author,
          comment_text: this.new_comment.comment_text,
        })
        .then((res) => {
          this.commentaries.push(res.data);
          snackbar.show({
            text: "Commmentaire ajouté",
            pos: "top-center",
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel[2],
          });
          this.new_comment = {
            comment_author: "",
            comment_text: "",
          };
        })
        .catch((err) => {
          snackbar.show({
            text: err.response.data,
            pos: "top-center",
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel[0],
          });
        });
    },
    remove(id) {
      axios
        .delete("/comments/" + id)
        .then((res) => {
          this.commentaries = this.commentaries.filter((c) => c._id != id);
          snackbar.show({
            text: res.data,
            pos: "top-center",
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel[2],
          });
        })
        .catch((err) => {
          snackbar.show({
            text: err.response.data,
            pos: "top-center",
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel[0],
          });
        });
    },
  },
};
</script>
<style src="@/views/Admin/subViews/style.css" scoped></style>

<style scoped>
#collect {
}

#form_newComment{
  margin-left: 4vw;
}

#form_newComment > label, button{
  margin-top: 5%;
  margin-bottom: 3%;
}
#block_img {
  display: block;
  width: 18%;
  height: 100%;
  margin-left: 2%;
}
#block_field {
  display: block;
  width: 80%;
  margin-left: 2%;
}
#comment_container {
  display: flex;
  widows: 100vw;
  height: 70vh;
  flex-direction: column;
  
  position: relative;
  top: 10vh;
 
  overflow-y:auto;

  margin-left: 5vw;
  margin-right: -15vw;
}
.card_comment {
  padding: 25px;
  border: solid black 1px;
}

.card_comment > img {
  display: block;
  position: relative;
  width: 25px;
  height: 25px;
  right: 0;
  top: 0;
}

.btn_delete{
  display: block ;
  position: relative;
  float: right;
  
}


</style>